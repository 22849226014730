import React from 'react';
import { motion } from 'framer-motion';

const OpizzaiolSecurityPolicy = () => {
  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  const TableRow = ({ version, supported }) => (
    <tr className="border-b">
      <td className="py-2 px-4 border-r">{version}</td>
      <td className="py-2 px-4 text-center">{supported ? '✅' : '❌'}</td>
    </tr>
  );

  return (
    <div className="min-h-screen bg-gray-50 pt-24 pb-12">
      <motion.div 
        className="container mx-auto px-4 max-w-4xl"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
        transition={{ duration: 0.5 }}
      >
        <h1 className="text-4xl font-bold text-gray-900 mb-8">Politique de Sécurité</h1>
        
        <section className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Vue d'ensemble de l'application</h2>
          <p className="text-gray-600">
            Cette application Flutter gère les données d'un food truck via Firebase. 
            Elle traite des informations sensibles comme les coordonnées des clients, les horaires de service et les commandes.
          </p>
        </section>

        <section className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Versions supportées</h2>
          <table className="w-full border">
            <thead className="bg-gray-50">
              <tr>
                <th className="py-2 px-4 border-r text-left">Version</th>
                <th className="py-2 px-4 text-center">Support</th>
              </tr>
            </thead>
            <tbody>
              <TableRow version="1.0.x" supported={true} />
              <TableRow version="1.1.x" supported={true} />
              <TableRow version="1.2.x" supported={true} />
            </tbody>
          </table>
        </section>

        {[
          {
            title: "Sécurité Firebase",
            items: [
              "Configuration stricte de Firebase Authentication",
              "Règles de sécurité Firestore pour la protection des données clients",
              "Règles de stockage Firebase pour la gestion sécurisée des fichiers",
              "Configuration de Crashlytics conforme au RGPD"
            ]
          },
          {
            title: "Protection des Données Utilisateurs",
            items: [
              "Chiffrement des données sensibles stockées localement via Hive",
              "Nettoyage automatique du cache après déconnexion",
              "Pas de stockage en clair des informations de paiement",
              "Anonymisation des données de crash reporting"
            ]
          },
          {
            title: "Authentification et Autorisations",
            items: [
              "Gestion des sessions utilisateur",
              "Déconnexion automatique après période d'inactivité",
              "Différents niveaux d'accès",
            ],
            subItems: [
              "Client : accès lecture au menu et à ses propres commandes",
              "Personnel : accès aux commandes en cours",
              "Administrateur : accès complet avec gestion du menu"
            ]
          }
        ].map((section, index) => (
          <section key={index} className="bg-white rounded-lg shadow-md p-6 mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">{section.title}</h2>
            <ul className="list-disc pl-6 text-gray-600 space-y-2">
              {section.items.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
              {section.subItems && (
                <ul className="list-circle pl-6 mt-2 space-y-1">
                  {section.subItems.map((subItem, i) => (
                    <li key={i}>{subItem}</li>
                  ))}
                </ul>
              )}
            </ul>
          </section>
        ))}

        <section className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Contact</h2>
          <div className="text-gray-600">
            <p className="mb-4">Pour toute question relative à la sécurité :</p>
            <ul className="list-disc pl-6 space-y-2">
              <li>Email : opizzaiol@proton.me</li>
            </ul>
          </div>
        </section>

        <section className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Engagement</h2>
          <p className="text-gray-600 mb-4">Nous nous engageons à :</p>
          <ul className="list-disc pl-6 text-gray-600 space-y-2">
            <li>Répondre aux alertes de sécurité sous 24h</li>
            <li>Informer rapidement en cas de fuite de données</li>
            <li>Maintenir à jour cette politique de sécurité</li>
            <li>Former régulièrement notre équipe aux enjeux de sécurité</li>
          </ul>
          <p className="mt-6 text-sm text-gray-500 italic">Dernière mise à jour : 13 mars 2025</p>
        </section>
      </motion.div>
    </div>
  );
};

export default OpizzaiolSecurityPolicy;