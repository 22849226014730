import React from 'react';
import { motion } from 'framer-motion';

const BurguiStreetSecurityPolicy = () => {
  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  const TableRow = ({ version, supported }) => (
    <tr className="border-b">
      <td className="py-2 px-4 border-r">{version}</td>
      <td className="py-2 px-4 text-center">{supported ? '✅' : '❌'}</td>
    </tr>
  );

  return (
    <div className="min-h-screen bg-gray-50 pt-24 pb-12">
      <motion.div 
        className="container mx-auto px-4 max-w-4xl"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
        transition={{ duration: 0.5 }}
      >
        <h1 className="text-4xl font-bold text-gray-900 mb-8">Politique de Sécurité Burgui Street</h1>
        
        <section className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Vue d'ensemble de l'application</h2>
          <p className="text-gray-600">
            L'application Burgui Street est une solution de gestion de commandes pour un food truck spécialisé dans les burgers artisanaux. 
            Elle sécurise les données des clients, les commandes et les informations de service.
          </p>
        </section>

        <section className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Versions supportées</h2>
          <table className="w-full border">
            <thead className="bg-gray-50">
              <tr>
                <th className="py-2 px-4 border-r text-left">Version</th>
                <th className="py-2 px-4 text-center">Support</th>
              </tr>
            </thead>
            <tbody>
              <TableRow version="1.0.x" supported={true} />
              <TableRow version="1.1.x" supported={true} />
              <TableRow version="1.2.x" supported={true} />
            </tbody>
          </table>
        </section>

        {[
          {
            title: "Sécurité Firebase",
            items: [
              "Authentification renforcée avec multi-facteurs",
              "Règles Firestore limitant l'accès aux données personnelles",
              "Chiffrement des données en transit et au repos",
              "Monitoring de sécurité en temps réel via Firebase Security Rules"
            ]
          },
          {
            title: "Protection des Données Utilisateurs",
            items: [
              "Cryptage local des informations sensibles",
              "Effacement automatique du cache après déconnexion",
              "Anonymisation des données de paiement",
              "Conformité RGPD pour la gestion des données personnelles"
            ]
          },
          {
            title: "Authentification et Autorisations",
            items: [
              "Gestion granulaire des sessions utilisateur",
              "Déconnexion automatique après période d'inactivité",
              "Système de rôles et permissions"
            ],
            subItems: [
              "Client : consultation du menu et suivi des commandes",
              "Personnel : gestion des commandes en cours",
              "Administrateur : configuration complète de l'application"
            ]
          }
        ].map((section, index) => (
          <section key={index} className="bg-white rounded-lg shadow-md p-6 mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">{section.title}</h2>
            <ul className="list-disc pl-6 text-gray-600 space-y-2">
              {section.items.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
              {section.subItems && (
                <ul className="list-circle pl-6 mt-2 space-y-1">
                  {section.subItems.map((subItem, i) => (
                    <li key={i}>{subItem}</li>
                  ))}
                </ul>
              )}
            </ul>
          </section>
        ))}

        <section className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Contact Sécurité</h2>
          <div className="text-gray-600">
            <p className="mb-4">Pour toute question de sécurité, contactez :</p>
            <ul className="list-disc pl-6 space-y-2">
              <li>Email : hello@burguistreet.com</li>
              <li>Téléphone : +262 (0)693 61 61 12</li>
            </ul>
          </div>
        </section>

        <section className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Notre Engagement Sécurité</h2>
          <p className="text-gray-600 mb-4">Nous nous engageons à :</p>
          <ul className="list-disc pl-6 text-gray-600 space-y-2">
            <li>Traiter toute alerte de sécurité sous 24h</li>
            <li>Transparence en cas d'incident de sécurité</li>
            <li>Mise à jour continue de nos pratiques de sécurité</li>
            <li>Formation régulière de l'équipe aux enjeux de cybersécurité</li>
          </ul>
          <p className="mt-6 text-sm text-gray-500 italic">Dernière mise à jour : 15 mars 2025</p>
        </section>
      </motion.div>
    </div>
  );
};

export default BurguiStreetSecurityPolicy;